<template>
  <v-row class="ma-0" align="center" justify="center">
    <v-row class="ma-0 w-100" align="center" justify="center" v-if="loading" style="height: 400px;">
        <div class="setting-spinner-loader"></div>
    </v-row>
    <v-card class="w-full h-auto pa-5 pa-sm-7 pa-md-9 pa-lg-9 pa-xl-9 " v-else>
        <invoice-agent v-if="agentModel" :model="agentModel"/>
        <v-row class="ma-0 mb-3" align="center" justify="center">
            <div class="black--text text-md-h4 text-lg-h4 text-xl-h4 text-center w-full font-weight-bold">
                ESTIMATE # {{ model.id }}
            </div>
        </v-row>
        <!-- HEADER -->
        <invoice-header :model="model && model.scan ? model.scan : null"
            :assessor="model.assessor ? model.assessor : null"/>
        <template v-if="model.items">
            <!-- PDR -->
            <invoice-item :lists="pdrsList" :name="'pdr'"
                :total="totalModel && totalModel.pdr ? totalModel.pdr : 0" />

            <!-- CONVENTIONAL -->
            <invoice-item :lists="conventionalsList" :name="'Conventional'"
                :total="totalModel && totalModel.conventional ? totalModel.conventional : 0" />

            <!--  P2P-->
            <invoice-item :lists="p2psList" :name="'p2p'"
                :total="totalModel && totalModel.p2p ? totalModel.p2p : 0" />

            <!-- REPLACE -->
            <invoice-item :lists="replacementsList" :name="'replace'"
                :total="totalModel && totalModel.replacement ? totalModel.replacement : 0" />
        </template>

        <!-- PRODUCTS -->
        <invoice-item :lists="productsList" :name="'products'" v-if="productsList.length"
            :total="totalModel && totalModel.product ? totalModel.product : 0" />

         <!-- PARTS -->
        <h6 class="text-uppercase font-weight-regular my-3" v-if="partsList.length">PARTS</h6>
        <v-row class="ma-0" align="center" v-for="part in partsList" :key="part">
            <v-col cols="12" class="text-uppercase py-1">{{part}}</v-col>
        </v-row>

        <v-divider class="black my-3" v-if="model.items || productsList.length || partsList.length" ></v-divider>
        <template v-if="totalModel">
            <v-row class="ma-0 black--text" align="center" justify="end" v-for="total in totalLists"
                :key="total.field">
                <v-col cols="6" md="2" lg="2" xl="2" sm="9" class="font-weight-bold text-end pa-0 py-1 text-break">{{total.title}}</v-col>
                <v-col cols="6" md="2" lg="2" xl="2" sm="3" class="font-weight-bold text-end pa-0 py-1">
                    {{ totalModel && totalModel[total.field] ? Number(totalModel[total.field]).toFixed(2) : 0}}
                </v-col>
            </v-row>
        </template>
        <v-row class="ma-0 mt-3 black--text" align="center" justify="end">
            <v-col cols="6" md="2" lg="2" xl="2" sm="9" class="font-weight-bold text-end pa-0 py-1 text-break">Products Total</v-col>
            <v-col cols="6" md="2" lg="2" xl="2" sm="3" class="font-weight-bold text-end pa-0 py-1">
                {{totalModel && totalModel.product ? Number(totalModel.product).toFixed(2) : 0}}
            </v-col>
        </v-row>
        <v-divider class="black my-3" v-if="totalModel"></v-divider>
        <template v-if="totalModel">
            <v-row class="ma-0 mt-2 black--text" align="center" justify="end" v-for="grand in grandTotalList"
                :key="grand.field">
                <v-col cols="6" md="2" lg="2" xl="2" sm="9" class="font-weight-bold text-end pa-0 py-1 text-break">{{grand.title}}</v-col>
                <v-col cols="6" md="2" lg="2" xl="2" sm="3" class="font-weight-bold text-end pa-0 py-1">
                    {{totalModel && totalModel[grand.field] ? Number(totalModel[grand.field]).toFixed(2) : 0}}
                </v-col>
            </v-row>
        </template>
    </v-card>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ScanQuotePreview',
    components: {
        invoiceAgent: () => import("./components/invoice/AgentDetails"),
        invoiceHeader: () => import("./components/invoice/InvoiceHeader"),
        invoiceItem: () => import("./components/invoice/InvoiceItems")
    },
    data: (vm) => ({
        quoteScanId: vm.$route.params.quoteScanId,
        partsList: [],
        productsList: [],
        pdrsList: [],
        conventionalsList: [],
        replacementsList: [],
        p2psList: [],
        model: {},
        agentModel: null,
        loading: false,
        totalModel: null,
        totalLists: [{field: 'pdr', title: 'PDR Total'}, {field: 'conventional', title: 'Conventional Total'},
            {field: 'p2p', title: 'Push To Paint Total'}, {field: 'replacement', title: 'Replace Total'}],
        grandTotalList: [{field: 'sub_total', title: 'Sub Total'}, {field: 'tax', title: 'GST'},
            {field: 'grant_total', title: 'Grand Total'}]
    }),
    created() {
        this.loading = true;
        this.getSelectedQuote(this.quoteScanId).then(resp => {
            this.setModelLists(resp);
            if (resp && resp.scan && resp.scan.agent_id) {
                this.getSelectedAgent(resp.scan.agent_id)
                    .then(response => {
                        this.agentModel = response;
                        this.loading = false;
                    }).catch(err => this.loading = false)
            } else this.loading = false;
        }).catch(err => this.loading = false);
    },
    methods: {
        ...mapActions(['getSelectedQuote', 'getSelectedAgent']),

        setModelLists(data) {
            this.model = data;
            this.totalModel = data && data.totals ? data.totals : null;
            this.partsList = data.parts || [];
            this.productsList = data.products || [];
            this.conventionalsList = this.objIntoArray(data.items, 'conventional');
            this.replacementsList = this.objIntoArray(data.items, 'replacement');
            this.p2psList = this.objIntoArray(data.items, 'p2p');
            this.pdrsList = this.objIntoArray(data.items, 'pdr');
        },
        objIntoArray(items, type) {
            const LIST = Object.keys(items).map(itemKey => items[itemKey].repair_type && items[itemKey].repair_type == type && items[itemKey]);
            return LIST.filter(item => item);
        }
    }
}
</script>